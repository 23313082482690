/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from 'react'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet'
import { useLocation } from '@reach/router'
import { useStaticQuery, graphql } from 'gatsby'

function SEO({ title, description, image, lang, meta }) {
  const { pathname } = useLocation()
  const { site, datoCmsSite } = useStaticQuery(query)
  const fallbackSeo = datoCmsSite.globalSeo.fallbackSeo
  const { titleSuffix, twitterAccount } = datoCmsSite.globalSeo

  const metaUrl = `${site.siteMetadata.siteUrl}${pathname}`
  const metaTitle = title || fallbackSeo.title
  const metaDescription = description || fallbackSeo.description

  const metaImage = () => {
    if (image) {
      return `${site.siteMetadata.siteUrl}${image}`
    }

    return fallbackSeo.image.url
  }

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={metaTitle}
      // titleTemplate={`%s | ${titleSuffix}`}
      meta={meta}
    >
      <meta name="description" content={metaDescription} />
      <meta http-equiv="content-language" content="en-gb" />
      {/* TWITTER CARD */}
      <meta property="twitter:account_id" content="732508568" />
      <meta name="twitter:card" content={fallbackSeo.twitterCard} />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={metaDescription} />
      <meta name="twitter:site" content={twitterAccount} />
      <meta name="twitter:creator" content={twitterAccount} />
      <meta name="twitter:image" content={metaImage()} />
      <meta name="twitter:image:width" content="1200" />
      <meta name="twitter:image:height" content="630" />
      {/* FACEBOOK OPEN GRAPH */}
      <meta property="fb:page_id" content="1124253900970002" />
      <meta name="og:title" content={title} />
      <meta name="og:description" content={metaDescription} />
      <meta name="og:type" content="website" />
      <meta property="og:url" content={metaUrl} />
      <meta property="og:image" content={metaImage()} />
      <meta property="og:image:width" content="1200" />
      <meta property="og:image:height" content="630" />
    </Helmet>
  )
}

SEO.defaultProps = {
  description: ``,
  image: null,
  lang: `en-gb`,
  meta: [],
}

SEO.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  image: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
}

export default SEO

const query = graphql`
  query {
    site {
      siteMetadata {
        siteUrl
      }
    }
    datoCmsSite {
      globalSeo {
        titleSuffix
        twitterAccount
        # facebookPageUrl
        fallbackSeo {
          title
          description
          twitterCard
          image {
            url(imgixParams: { w: "1200", h: "630", fit: "max", fm: "jpg" })
            width
            height
          }
        }
      }
    }
  }
`

export const FragmentQuery = graphql`
  fragment SeoImageSharpFragment on File {
    childImageSharp {
      fixed(width: 1200, height: 630, fit: INSIDE, toFormat: JPG) {
        src
      }
    }
  }
`
