import React from 'react'

import Page from '../../components/Page'
import SEO from '../../components/SEO'
import PageIntro from '../../components/PageIntro'
import Newsletter from '../../components/Newsletter'

const ContactUsSuccessPage = () => {
  return (
    <Page className="p-contact-us has-fingerPrints">
      <SEO title="Contact us" />

      <PageIntro
        title={`<strong>Contact</strong> us`}
        text={`<strong>Success!</strong>`}
        image={`contact/Contact-Icon`}
      />

      <section className="c-section c-section--contact-us">
        <div className="c-section__outer container">
          <div className="c-section__inner pt-0 pb-1">
            <div className="c-sectionTitle">
              <div className="row">
                <div className="col-12 col-lg-7 offset-lg-3">
                  <p>
                    Thank you, your message has been sent and we will be in
                    touch shortly!
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Newsletter />
    </Page>
  )
}

export default ContactUsSuccessPage
